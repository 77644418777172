import {on, findAllIn,findIn, find, hasClass} from '@elements/dom-utils';
import { onFind } from "@elements/init-modules-in-scope";
import { toHash } from "@elements/hashing-sha256";
import { getPrefixedDataSet } from "@elements/data-set-utils";

const defaultSelectors = {
    base: '.js-form-tracking',
    input: '.js-form-tracking__input',
    select: '.js-form-tracking__select',
    checkbox: '.js-form-tracking__checkbox',
    btn: '.js-form-tracking__submit-btn',
    // supportForm: '.js-form-tracking--support',
    supportSection: '.js-form-tracking__support-section',
    supportCountry: '.js-form-tracking__support-country',
    // registerForm: '.js-form-tracking--register',
    // gatedContentForm: '.js-form-tracking--gated-content',
};

const defaultOptions = {
    debug: false,
    formEvent: 'form-validation.submit'
};

export function init(selectors = defaultSelectors, options = defaultOptions) {
    onFind(selectors.base, function (form) {
        let contactForm = find('.js-form-tracking--contact');
        // Support Form
        let supportForm = find('.js-form-tracking--support');
        // Support Form Overlay
        let supportFormOverlay = find('.js-form-tracking--support-overlay');
        if(supportFormOverlay){
            overlaySupportFormTracking(form, selectors);
        }

        // Register Form
        let registerForm = find('.js-form-tracking--register');
        let collectedCheckboxData = getCheckboxData(form, selectors);

        // Gated Content Form
        let gatedContentForm = find('.js-form-tracking--gated-content');

        // Support Matrix Form
        let supportMatrix = find('.js-form-tracking--support-matrix');

        on(options.formEvent, () => {
            // console.log('form-validation.submit');
            //expand with your own data
            //remove leadsUserData if no Enhanced Conversion Tracking is needed
            if(gatedContentForm){
                let collectedSelectData = getSelectData(form, selectors);
                window.dataLayer.push({
                    'event': 'generate_lead',
                    'contact_type': form.getAttribute('data-form-tracking-contact_type'),
                    'form_name': form.getAttribute('data-form-tracking-form_name'),
                    'lead_industry': collectedSelectData,
                    // 'leadsUserData': getLeadsUserData(form, selectors)
                });
            } else if(registerForm){
                let collectedCheckboxData = getCheckboxData(form, selectors);
                window.dataLayer.push({
                    'event': 'sign_up',
                    'registration_reasons': collectedCheckboxData,
                    // 'leadsUserData': getLeadsUserData(form, selectors)
                });
            } else if(supportMatrix){
                let collectedSelectData = getSelectData(form, selectors);
                window.dataLayer.push({
                    'event': 'generate_lead',
                    'form_name': form.getAttribute('data-form-tracking-form_name'),
                    'contact_type': form.getAttribute('data-form-tracking-contact_type'),
                    'lead_industry': collectedSelectData,
                    // 'leadsUserData': getLeadsUserData(form, selectors)
                });

            } else if(supportForm){
               let supportSection= find('.js-form-tracking__support-section'),
                   supportCountry= find('.js-form-tracking__support-country');
                window.dataLayer.push({
                    'event': 'support_form',
                    'support_section': supportSection.options[supportSection.selectedIndex].value,
                    'support_country':  supportCountry.options[supportCountry.selectedIndex].value,
                    'location': form.getAttribute('data-form-tracking-location'),
                    // 'leadsUserData': getLeadsUserData(form, selectors)
                });
            }else if(contactForm){
                // tracking Contact Form
                getGaClientId((error, gaClientId) => {
                    if (error) {
                        console.error('Error getting GA Client ID:', error);
                        window.dataLayer.push({
                            'event': 'generate_lead',
                            'contact_type' : form.getAttribute('data-form-tracking-contact_type'),
                            'form_name': form.getAttribute('data-form-tracking-form_name'),
                            'lead_industry':  getSelectData(form, selectors),
                            'leadsUserData': getLeadsUserData(form, selectors),
                            'current_lang': findIn('#current_lang', form).value,
                            'current_page': findIn('#current_page', form).value,
                            'gaclientid': error,
                            'traffic_source': trafficSource
                        });
                    } else {
                        let trafficSource = getTrafficSource();
                        window.dataLayer.push({
                            'event': 'generate_lead',
                            'contact_type' : form.getAttribute('data-form-tracking-contact_type'),
                            'form_name': form.getAttribute('data-form-tracking-form_name'),
                            'lead_industry':  getSelectData(form, selectors),
                            'leadsUserData': getLeadsUserData(form, selectors),
                            'current_lang': findIn('#current_lang', form).value,
                            'current_page': findIn('#current_page', form).value,
                            'gaclientid': gaClientId,
                            'traffic_source': trafficSource
                        });
                    }
                });
            }

            if(options.debug){
                console.log('dataLayer: ', window.dataLayer);
            }
        }, form);

    });
}
function getCheckboxData(form, selectors) {
    let checkboxData = [];
    let checkboxes = findAllIn(selectors.checkbox, form);
    checkboxes.forEach(checkbox => {
        on('change', () => {
            if(checkbox.checked){
                let data = checkbox.value;
                checkboxData.push(data);
            } else {
                let index = checkboxData.indexOf(checkbox.value);
                if (index !== -1) {
                    checkboxData.splice(index, 1);
                }
            }

        }, checkbox);
    });
    return checkboxData;
}

function overlaySupportFormTracking(form, selectors) {
    let supportSection= find('.js-form-tracking__support-section'),
        supportCountry= find('.js-form-tracking__support-country'),
        submitBtn = findIn(selectors.btn, form);
    if(submitBtn){
        on('click', () => {
            window.dataLayer.push({
                'event':  submitBtn.getAttribute('data-form-tracking-event'),
                'support_section': supportSection.options[supportSection.selectedIndex].value,
                'support_country':  supportCountry.options[supportCountry.selectedIndex].value,
                'location': form.getAttribute('data-form-tracking-location'),
            });
        }, submitBtn);
    }
}
function getSelectData(form, selectors) {
    let selectData;
    let selects = findAllIn(selectors.select, form);
    selects.forEach(select => {
        selectData = select.options[select.selectedIndex].text;
    });
    return selectData;
}

/*leadsUserData Example
see: https://support.google.com/google-ads/answer/13262500?hl=de#zippy=%2Cvariablen-f%C3%BCr-erweiterte-conversions-ermitteln-und-festlegen

'leadsUserData': {
    'sha256_email_address': toHash(email),
    'sha256_phone_number': toHash(phoneNumber),
    'address': {
        sha265_first_name: toHash(firstname),
        sha256_last_name: toHash(lastname),
        street: streetAddress,
        city: 'Linz',
        region: 'Upper Austria',
        postal_code: '12345',
        country: 'de',
    }
}
*/

function getLeadsUserData(form, selectors) {
    let inputs = findAllIn(selectors.input, form);
    let leadsUserData = {};

    inputs.map(input => {
        let dataset = getPrefixedDataSet( 'form-tracking', input);

        if (dataset.attribute && (typeof dataset.attribute === 'string')) {
            if (dataset.attribute.includes('sha256_')) {
                leadsUserData[dataset.attribute] = toHash(input.value.trim().toLowerCase());
            }else {
                leadsUserData[dataset.attribute] = input.value;
            }
        }else if(dataset.attributeAddress && (typeof dataset.attributeAddress === 'string')){
            if(!leadsUserData.address) {
                leadsUserData.address = {};
            }

            if (dataset.attributeAddress.includes('sha256_')) {
                leadsUserData.address[dataset.attributeAddress] = toHash(input.value.trim().toLowerCase());
            }else {
                leadsUserData.address[dataset.attributeAddress] = input.value;
            }
        }
    });

    return leadsUserData;
}

function initializeGtag() {
    window.dataLayer = window.dataLayer || [];

    function gtag() {
        window.dataLayer.push(arguments);
    }

    gtag('js', new Date());
    gtag('config', 'G-6T0EF9QKXZ');

    return gtag;
}

function getGaClientId(callback) {
    const gtag = initializeGtag();
    gtag('get', 'G-6T0EF9QKXZ', 'client_id', (clientId) => {
        if (clientId) {
            callback(null, clientId);  // Kein Fehler, sende clientId
        } else {
            callback('Client ID not found');  // Fehlerfall
        }
    });
}

function getTrafficSource() {
    const urlParams = new URLSearchParams(window.location.search);
    const utmSource = urlParams.get('utm_source');
    const utmMedium = urlParams.get('utm_medium');
    const utmCampaign = urlParams.get('utm_campaign');

    if (utmSource && utmMedium) {
        return {
            source: utmSource,
            medium: utmMedium,
            campaign: utmCampaign || 'not set'
        };
    }

    let currentHostname = window.location.hostname;
    let referrerHostname = document.referrer ? new URL(document.referrer).hostname : '';

    return {
        source: referrerHostname === currentHostname ? document.referrer : (referrerHostname || 'direct'),
        medium: document.referrer ? 'referral' : 'none'
    };
}
